import { type StackFrame } from '@sentry/types'

const cachebustingRegex = /\.([a-f0-9]{12})\.js$/

// Whitenoise adds a 12 character cachebusting slug to the end of the filename (e.g. main.4711fe9a2add.js)
// However, this slug is not present in the sourcemap we generate at build time.
// This function strips the cachebusting slug from the filename so that Sentry can match the sourcemap to the frame.
export const stripCachebustingFromFrame = (frame: StackFrame): StackFrame => {
  if (frame.filename !== undefined && cachebustingRegex.test(frame.filename)) {
    frame.filename = frame.filename.replace(cachebustingRegex, '.js')
  }
  return frame
}
