import WebUsbReader from '../services/WebUsbReader'

export function markDomAsWebUsbAvailable (): void {
  const WEB_USB_AVAILABLE = 'data-web-usb-available'
  if ((new WebUsbReader()).webUsbSupported()) {
    const webUsbSensitiveElements = document.querySelector(`[${WEB_USB_AVAILABLE}]`)
    if (webUsbSensitiveElements != null) {
      webUsbSensitiveElements.setAttribute(WEB_USB_AVAILABLE, 'true')
    }
  }
}
