import Cookies from "js-cookie";

export function createCookie(name, value, days) {
  Cookies.set(name, value, { expires: days });
}

export function readCookie(name) {
  return Cookies.get(name);
}

function getCookiePopup() {
  return document.querySelector(".cookie-popup");
}

function getCookiePopupButton() {
  return document.querySelector(".cookie-popup__accept-button");
}

createCookie("testCookies", "yes", 365);

if (
  readCookie("testCookies") === "yes" &&
  readCookie("acceptCookies") !== "yes"
) {
  getCookiePopup().style.display = "block";

  getCookiePopupButton().addEventListener(
    "click",
    function () {
      createCookie("acceptCookies", "yes", 365);
      getCookiePopup().style.display = "none";
    },
    false,
  );
}
