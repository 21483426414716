import { CookiesNotAccepted, getCookie, setCookie } from '../../helpers/cookies'

interface RecentSearch {
  query: string
  url: string
}

export default class RecentSearchService {
  static recentSearchCookieName = 'recentsearches'

  private readonly searchEndpoint: string
  private readonly searchQueryParameter: string

  constructor (searchEndpoint: string, searchQueryParameter: string) {
    this.searchEndpoint = searchEndpoint
    this.searchQueryParameter = searchQueryParameter
  }

  private static setRecentSearchCookie (recentSearches: string[]): void {
    try {
      setCookie(RecentSearchService.recentSearchCookieName, JSON.stringify(recentSearches), 30)
    } catch (err) {
      if (!(err instanceof CookiesNotAccepted)) {
        throw err
      }
    }
  }

  private buildSearchUrl (query: string): string {
    const searchParams = new URLSearchParams()
    searchParams.append(this.searchQueryParameter, query)
    return `${this.searchEndpoint}?${searchParams.toString()}`
  }

  getRecentSearches (): RecentSearch[] {
    return RecentSearchService.fetchRecentSearches().map((query: string) => (
      { query, url: this.buildSearchUrl(query) }
    ))
  }

  private static fetchRecentSearches (): string[] {
    const recentSearchCookie = getCookie(
      RecentSearchService.recentSearchCookieName
    ) ?? ''
    const recentSearchesString = recentSearchCookie !== '' ? recentSearchCookie : '[]'
    return JSON.parse(recentSearchesString)
  }

  static addRecentSearch (query: string): void {
    if (query === '') return

    let recentSearches = this.fetchRecentSearches()
    if (recentSearches.includes(query)) {
      recentSearches = recentSearches.filter((recentSearch) => recentSearch !== query)
    }

    recentSearches.unshift(query)
    if (recentSearches.length > 5) recentSearches.pop()

    RecentSearchService.setRecentSearchCookie(recentSearches)
  }

  static removeRecentSearch (query: string): void {
    if (query === '') return

    let recentSearches = this.fetchRecentSearches()
    recentSearches = recentSearches.filter((recentSearch) => recentSearch !== query)
    RecentSearchService.setRecentSearchCookie(recentSearches)
  }
}
