export interface ChildElement {
  domElement: HTMLElement
  select: () => void
  remove: () => void
}

export default class DropdownListItem {
  domElement: HTMLLIElement
  childElement: ChildElement
  className: string

  constructor (className: string, childElement: ChildElement) {
    this.className = className
    this.childElement = childElement

    this.render()
  }

  toggleFocused (): void {
    this.domElement.classList.toggle(
            `${this.className}--focused`
    )
  }

  private buildDomElement (): HTMLLIElement {
    const listElement = document.createElement('li')
    listElement.classList.add(this.className)

    return listElement
  }

  select (): void {
    this.childElement.select()
  }

  render (): void {
    const listItemElement = this.buildDomElement()
    listItemElement.appendChild(this.childElement.domElement)

    this.domElement = listItemElement
  }

  remove (): void {
    this.childElement.remove()
    this.domElement.remove()
  }
}
