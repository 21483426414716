import { GraphQLClient } from 'graphql-request'
import { getSdk } from './graphql/schema'
import { type BoardSuggestion } from './graphql/types'

export async function getSuggestions (host: string, query: string, cmsisEcosystemSupport: boolean): Promise<BoardSuggestion[]> {
  const client = new GraphQLClient(host)
  const sdk = getSdk(client)

  return (
    await sdk.getBoardSuggestions({ query, cmsisEcosystemSupport })
      .then((response) => response.get_board_suggestions)
  )
}
