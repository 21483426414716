import * as Sentry from '@sentry/browser'
import { RewriteFrames } from '@sentry/integrations'

import { stripCachebustingFromFrame } from './services/SentryHelpers'

const appVersion = document.getElementById('app-version')?.value
const dsn = document.getElementById('sentry-dsn')?.value

if (dsn !== 'None') {
  Sentry.init({
    dsn,
    // Ignore errors from specified urls.
    // https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
    ],
    ignoreErrors:[
      "EvalError"
    ],
    integrations: [
      new RewriteFrames({
        iteratee: stripCachebustingFromFrame
      })
    ],
    release: appVersion
  })
}

const urlParams = new URLSearchParams(window.location.search)
const sentryTest = urlParams.get('sentryTest')
if (sentryTest === 'true') {
  throw new Error('Test error')
}
