export function addPackDownloadLinkTracking(link: HTMLAnchorElement): void {
  link.addEventListener("click", () => {
    gtag("event", "pack_download", {
      pack_name: link.dataset.packName,
      pack_vendor: link.dataset.packVendor,
      pack_version: link.dataset.packVersion,
    });
  });
}

export function addExampleDownloadLinkTracking(link: HTMLAnchorElement): void {
  link.addEventListener("click", () => {
    gtag("event", "example_download", {
      download_cta: link.dataset.downloadCta,
      example_board_name: link.dataset.boardName,
      example_board_vendor: link.dataset.boardVendor,
      example_name: link.dataset.exampleName,
      example_type: link.dataset.exampleType,
    });
  });
}
