export default class CopyButton {
  controlElement: HTMLButtonElement
  content: string

  constructor(controlElement: HTMLButtonElement) {
    this.controlElement = controlElement
    this.content = this.getCopyButtonTargetContent()

    this.setupEventListeners()
  }

  private getCopyButtonTargetContent(): string {
    const copyButtonId = this.controlElement.dataset.copyButtonId ?? ''
    const selector = `[data-copy-content-id="${copyButtonId}"]`
    const parentElement = this.controlElement.parentElement
    const copyContentElement = parentElement?.querySelector<HTMLInputElement>(selector)
    const copyContent = copyContentElement?.value
    if (copyContent == null) {
      throw new Error('Unable to find copy content.')
    }
    return copyContent
  }

  private setupEventListeners(): void {
    this.controlElement.addEventListener('click', (event) => {
      event.preventDefault()
      this.copyContentToClipboard()
    })
  }

  private copyContentToClipboard(): void {
    navigator.clipboard.writeText(this.content).then(() => undefined, () => undefined);
  }
}

export function initCopyButton(): void {
  up.compiler("[data-copy-button-id]", (copyButton: HTMLButtonElement) => {
    new CopyButton(copyButton)
  });
}
