import { getSerialNumber, STDevice } from '@arm-debug/webusb-debug-device'

export interface WebUsbReaderI {
  webUsbSupported: () => boolean
  requestDevice: () => Promise<USBDevice>
  isStLinkDevice: (device: USBDevice) => boolean
  getStDeviceProductCode: (device: USBDevice) => Promise<string>
  getDeviceSerial: (device: USBDevice) => Promise<string>
}

const STVENDOR = 0x0483

export default class WebUsbReader implements WebUsbReaderI {
  webUsbSupported (): boolean {
    return navigator?.usb !== undefined
  }

  async requestDevice (): Promise<USBDevice> {
    const usbNavigator = navigator?.usb

    if (usbNavigator !== undefined) {
      return await usbNavigator.requestDevice({ filters: [] })
    } else {
      throw new Error('No device returned')
    }
  }

  isStLinkDevice (device: USBDevice): boolean {
    return device.vendorId === STVENDOR
  }

  async getStDeviceProductCode (device: USBDevice): Promise<string> {
    const stDevice = new STDevice(device, 1, 0, 0, true)

    await stDevice.connect()

    const productIds = await stDevice.getIdentifiers()
    return productIds.mbed
  }

  async getDeviceSerial (device: USBDevice): Promise<string> {
    return await getSerialNumber(device)
  }
}
