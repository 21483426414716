import "./initSentry";

import "./global";
import "./boardSearch";
import { initAnalyticsEvents } from "./analytics/index";
import { markDomAsWebUsbAvailable } from "./components/MarkWebUsbAvailable";
import { initCopyButton } from "./components/CopyButton";
import { initHardwareDetector } from "./components/HardwareDetectionWidget";
import { initAccordion } from "./components/Accordion";
import { initExtendableContent } from "./components/ExtendableContent";

markDomAsWebUsbAvailable();
initHardwareDetector();
initCopyButton();
initAccordion();
initExtendableContent();
initAnalyticsEvents();
