import HardwareService, { type HardwareServiceI } from './HardwareService'
import {
  type Board,
  GraphQlBoardRepository
} from './BoardRepository'
import WebUsbConnectedDeviceService, { type ConnectedDeviceServiceI } from './ConnectedDeviceService'

export interface HardwareDetectorI {
  detectHardware: () => Promise<Board>
}

export default class HardwareDetector implements HardwareDetectorI {
  constructor (
    private readonly connectedDeviceService: ConnectedDeviceServiceI = new WebUsbConnectedDeviceService(),
    private readonly hardwareService: HardwareServiceI = new HardwareService(
      new GraphQlBoardRepository()
    )
  ) {}

  async detectHardware (): Promise<Board> {
    return await this.connectedDeviceService.getConnectedDeviceProductCode().then(this.hardwareService.getBoardForProductCode)
  }
}
