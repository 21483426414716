import MultiSelectDropdown from './components/MultiSelectDropdown'
import SearchBox from './components/SearchBox'
import SelectDropdown from './components/SelectDropdown'
import RecentSearchService from './services/recent_searches/service'
import SearchService from './services/graphql_service/service'
import ListFilter from './components/ListFilter'

up.compiler('[data-form-id="board_search"]', (form) => {
  up.on(form, 'submit', () => {
    const input = form.querySelector('[name="q"]')
    RecentSearchService.addRecentSearch(input.value)
  })
})

up.compiler('.board-search', (
  boardSearch: HTMLDivElement,
  data: { searchApiHost: string, boardsEndpoint: string, query: string }
): void => {
  const services = {
    search: new SearchService(
      data.searchApiHost,
      data.boardsEndpoint
    ),
    recentSearch: new RecentSearchService(
      data.boardsEndpoint,
      'q'
    )
  }

  const dropdownListClassNames = {
    list: 'board-search__suggestions',
    listItem: 'board-search__suggestion',
    title: 'board-search__suggestion-title'
  }

  const boardSearchBox = new SearchBox(
    boardSearch,
    500,
    services,
    dropdownListClassNames
  )
  boardSearchBox.setDropdownListItems(
    up.util.isBlank(data.query) === true ? '' : data.query
  ).catch(console.error)
})

const initSelectDropdown = (id: string, formIds: string[]): void => {
  const controlSelector = `[data-dropdown-button-id='${id}']`
  const contentSelector = `[data-dropdown-content-id='${id}']`
  let dropdown

  up.compiler(controlSelector, (dropdownButtonElement) => {
    dropdown = new SelectDropdown(dropdownButtonElement)
  })

  up.compiler(`${contentSelector} [data-dropdown-results]`, () => {
    dropdown.setupCloseOnInputClickListener()
  })

  for (const formId of formIds) {
    up.compiler(`[data-form-id=${formId}]`, (form) => {
      up.on(form, 'submit', () => {
        dropdown.clearSelections()
      })
    })
  }
}

const initMultiSelectDropdown = (id: string, formIds: string[]): void => {
  const controlSelector = `[data-dropdown-button-id='${id}']`
  const contentSelector = `[data-dropdown-content-id='${id}']`
  let dropdown

  up.compiler(controlSelector, (dropdownButtonElement) => {
    dropdown = new MultiSelectDropdown(dropdownButtonElement)
  })

  up.compiler(`${contentSelector} [data-dropdown-results]`, () => {
    dropdown.disableIfEmpty()
  })

  up.compiler(`${controlSelector} [data-dropdown-clear-button]`, (clearButton) => {
    dropdown.setupClearButtonListener(clearButton)
  })
  if(id==="vendor_filters"){
  up.compiler(`.dropdown-content-container`, (containerElement) => {
    new ListFilter(containerElement)
  })
}

  for (const formId of formIds) {
    up.compiler(`[data-form-id=${formId}]`, (form) => {
      up.on(form, 'submit', () => {
        dropdown.clearSelections()
      })
    })
  }
}

initMultiSelectDropdown('vendor_filters', ['board_search', 'device_search', 'pack_search'])
initMultiSelectDropdown('core_filters', ['board_search', 'device_search'])
initMultiSelectDropdown('content_type_filters', ['pack_search'])
initSelectDropdown('sort_by', ['board_search', 'device_search', 'pack_search'])
