export default class Dropdown {
  controlElement: HTMLButtonElement
  contentElement: HTMLElement

  constructor (controlElement: HTMLButtonElement) {
    this.controlElement = controlElement
    this.contentElement = this.getDropdownContentElement()

    this.setupEventListeners()
  }

  private getDropdownContentElement (): HTMLElement {
    const dropdownId = this.controlElement.dataset.dropdownButtonId ?? ''
    const selector = `[data-dropdown-content-id="${dropdownId}"]`
    const parentElement = this.controlElement.parentElement
    const dropdownContentElement = parentElement?.querySelector<HTMLElement>(selector)
    if (dropdownContentElement == null) {
      throw new Error('Unable to find dropdown content element.')
    }
    return dropdownContentElement
  }

  private setupEventListeners (): void {
    this.controlElement.addEventListener('click', (event) => {
      event.preventDefault()
      this.toggleContent()
    })

    document.addEventListener('click', (event: Event) => {
      if (event.target !== null && !this.dropdownContainsElement(event.target)) {
        this.hideContent()
      }
    })
  }

  public hideContent (): void {
    this.contentElement.style.visibility = 'hidden'
  }

  private showContent (): void {
    this.contentElement.style.visibility = 'visible'
  }

  private toggleContent (): void {
    if (this.contentElement.style.visibility === 'visible') {
      this.hideContent()
    } else {
      this.showContent()
    }
  }

  private dropdownContainsElement (element: EventTarget): boolean {
    return this.controlElement.contains(element as Node) || this.contentElement.contains(element as Node)
  }
}
