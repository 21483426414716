import RecentSearchService from '../services/recent_searches/service'

export default class RecentSearch {
  private readonly query: string
  private readonly href: string
  domElement: HTMLDivElement

  constructor (query: string, href: string) {
    this.query = query
    this.href = href

    this.dispatchMouseEnterEvent = this.dispatchMouseEnterEvent.bind(this)
    this.removeSearch = this.removeSearch.bind(this)
    this.select = this.select.bind(this)

    this.render()
    this.setupEventListeners()
  }

  private buildDomElement (): HTMLDivElement {
    const divText = document.createTextNode(this.query)

    const iconElement = document.createElement('i')
    iconElement.classList.add('fal', 'fa-times')

    const divElement = document.createElement('div')
    divElement.appendChild(divText)
    divElement.appendChild(iconElement)

    return divElement
  }

  private render (): void {
    this.domElement = this.buildDomElement()
  }

  getDomElement (): HTMLDivElement {
    return this.domElement
  }

  select (): void {
    window.location.href = this.href
  }

  private dispatchMouseEnterEvent (): void {
    this.domElement.dispatchEvent(new Event('dropdown-mouseenter', { bubbles: true }))
  }

  private preventDefaultMouseDown (event: MouseEvent): void {
    event.preventDefault()
  }

  private setupEventListeners (): void {
    this.domElement.addEventListener('click', this.select)
    this.domElement.addEventListener('mouseenter', this.dispatchMouseEnterEvent)
    this.domElement.addEventListener('mousedown', this.preventDefaultMouseDown)
    this.domElement.querySelector('i')?.addEventListener('click', this.removeSearch)
  }

  private removeEventListeners (): void {
    this.domElement.removeEventListener('mouseenter', this.dispatchMouseEnterEvent)
    this.domElement.removeEventListener('mousedown', this.preventDefaultMouseDown)
  }

  removeSearch (event: Event): void {
    event.stopPropagation()

    RecentSearchService.removeRecentSearch(this.query)
    this.getDomElement().dispatchEvent(new Event('recentsearch-remove', { bubbles: true }))
    this.remove()
  }

  remove (): void {
    this.removeEventListeners()
    this.domElement.remove()
  }
}
