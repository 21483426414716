
export default class SuggestionElement {
  domElement: HTMLAnchorElement

  constructor (text: string, href: string) {
    this.domElement = SuggestionElement.buildDomElement(text, href)

    this.dispatchSuggestionHoverEvent = this.dispatchSuggestionHoverEvent.bind(this)

    this.setupEventListeners()
  }

  private static buildDomElement (text: string, href: string): HTMLAnchorElement {
    const anchorText = document.createTextNode(text)

    const anchorElement = document.createElement('a')
    anchorElement.setAttribute('href', href)
    anchorElement.setAttribute('up-follow', '')
    anchorElement.appendChild(anchorText)

    return anchorElement
  }

  select (): void {
    this.domElement.click()
  }

  dispatchSuggestionHoverEvent (): void {
    this.domElement.dispatchEvent(new Event('dropdown-mouseenter', { bubbles: true }))
  }

  preventDefaultMouseDown (event: MouseEvent): void {
    event.preventDefault()
  }

  private setupEventListeners (): void {
    this.domElement.addEventListener('mouseenter', this.dispatchSuggestionHoverEvent)
    this.domElement.addEventListener('mousedown', this.preventDefaultMouseDown)
  }

  private removeEventListeners (): void {
    this.domElement.removeEventListener('mouseenter', this.dispatchSuggestionHoverEvent)
    this.domElement.removeEventListener('mousedown', this.preventDefaultMouseDown)
  }

  remove (): void {
    this.removeEventListeners()
    this.domElement.remove()
  }
}
