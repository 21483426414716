import {
  addPackDownloadLinkTracking,
  addExampleDownloadLinkTracking,
} from "./handlers";

export function initAnalyticsEvents(): void {
  up.compiler(
    "[data-event-tracking=pack_download]",
    (packDownloadLink: HTMLAnchorElement) => {
      addPackDownloadLinkTracking(packDownloadLink);
    },
  );

  up.compiler(
    "[data-event-tracking=example_download]",
    (exampleDownloadLink: HTMLAnchorElement) => {
      addExampleDownloadLinkTracking(exampleDownloadLink);
    },
  );
}
