import { type Board, type BoardRepositoryI } from './BoardRepository'

export interface HardwareServiceI {
  getBoardForProductCode: (string) => Promise<Board>
}

export default class HardwareService implements HardwareServiceI {
  constructor (private readonly boardRepository: BoardRepositoryI) {
    this.boardRepository = boardRepository

    this.getBoardForProductCode = this.getBoardForProductCode.bind(this)
  }

  async getBoardForProductCode (productCode: string): Promise<Board> {
    const board = (await this.boardRepository.getBoards()).find((board) => {
      return board.detectCode === productCode
    })

    if (board !== undefined) {
      return board
    } else {
      throw new Error('Board not found')
    }
  }
}
