import Dropdown from './Dropdown'
import SelectionControl from './SelectionControl'

export default class SelectDropdown {
  dropdown: Dropdown

  constructor (controlElement: HTMLButtonElement) {
    this.dropdown = new Dropdown(controlElement)
  }

  public clearSelections (): void {
    new SelectionControl(this.dropdown.contentElement).clearSelections()
  }

  public setupCloseOnInputClickListener (): void {
    const inputs = this.dropdown.contentElement.querySelectorAll('input')
    for (const radioInput of Array.from(inputs)) {
      radioInput.addEventListener('click', (event) => {
        event.stopPropagation()
        this.dropdown.hideContent()
      })
    }
  }
}
