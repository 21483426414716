import Cookies from 'js-cookie'

export const acceptCookiesName = 'acceptCookies'

export class CookiesNotAccepted extends Error {
  constructor () {
    super()
    Object.setPrototypeOf(this, CookiesNotAccepted.prototype)
  }
}

export function acceptCookies (): void {
  Cookies.set(acceptCookiesName, 'yes', { expires: 365 })
}

export function hasAcceptedCookies (): boolean {
  return getCookie(acceptCookiesName) === 'yes'
}

export function getCookie (name: string): string | undefined {
  return Cookies.get(name)
}

export function setCookie (name: string, value: string, expires: number): void {
  if (!hasAcceptedCookies()) throw new CookiesNotAccepted()

  Cookies.set(name, value, { expires })
}
