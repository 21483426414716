import { DOMElementNotFound } from "../errors";
import GlobalNavigationSubmenu from "./GlobalNavigationSubmenu";

export default class GlobalNavigation {
    containerElement: HTMLElement;
    controlElement: HTMLElement;
    mobileNavigationContainerElement: HTMLElement;

    constructor(containerElement: HTMLElement) {
        this.containerElement = containerElement;
        this.controlElement = this.getControlElement();
        this.mobileNavigationContainerElement = this.getMobileNavigationContainerElement();

        this.setupState();
        this.setupEventListeners();
        this.attachSubmenuInteractivity();
    }

    private getControlElement(): HTMLElement {
        const element = this.containerElement.querySelector<HTMLElement>('.keil-nav__control');

        if (element == null) throw new DOMElementNotFound('Unable to find keil nav control element');

        return element;
    }

    private getMobileNavigationContainerElement(): HTMLElement {
        const element = this.containerElement.querySelector<HTMLElement>('.keil-nav-mob__container');

        if (element == null) throw new DOMElementNotFound('Unable to find keil nav mobile container element');

        return element;
    }

    private getSubmenuElements(): NodeListOf<HTMLElement> {
        const elements = this.mobileNavigationContainerElement.querySelectorAll<HTMLElement>('.keil-nav-mob__menuitem[aria-haspopup="true"]');

        return elements
    }

    private attachSubmenuInteractivity(): void {
        const submenuElements = this.getSubmenuElements();

        for (let i = 0; i < submenuElements.length; i++) {
            new GlobalNavigationSubmenu(submenuElements[i]);
        }
    }

    private setupState(): void {
        if (this.controlElement.getAttribute('aria-expanded') == null) {
            this.controlElement.setAttribute('aria-expanded', 'false');
        }
    }

    private setupEventListeners(): void {
        this.controlElement.addEventListener('click', (event) => {
            this.toggleMobileNavigation();
        })
    }

    private toggleMobileNavigation(): void {
        if (this.controlElement.getAttribute('aria-expanded') === 'true') {
            this.controlElement.setAttribute('aria-expanded', 'false');
            delete this.mobileNavigationContainerElement.dataset.expanded;
        } else {
            this.controlElement.setAttribute('aria-expanded', 'true');
            this.mobileNavigationContainerElement.dataset.expanded = "";
        }
    }
}
