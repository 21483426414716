import { type Board } from '../services/BoardRepository'

export default class DetectedHardwareView {
  constructor (private readonly el: HTMLElement, private readonly board: Board) {}

  render (): void {
    const container = document.createElement('div')
    container.classList.add('hardware-detector__detected_board')

    const header = document.createElement('h3')
    header.classList.add('hardware-detector__detected_board__header')
    header.innerText = 'Detected hardware:'
    container.appendChild(header)

    const boardName = `${this.board.vendorName} ${this.board.name}`
    const boardPara = document.createElement('p')
    boardPara.classList.add('hardware-detector__detected_board__board')

    const boardLabel = document.createElement('span')
    boardLabel.classList.add('hardware-detector__detected_board__board__label')
    boardLabel.innerHTML = 'Board:'
    boardPara.appendChild(boardLabel)

    if (this.board.cmsisEcosystemSupport) {
      const boardLink = document.createElement('a')
      boardLink.classList.add('hardware-detector__detected_board__board__value')
      boardLink.href = `/boards/${this.board.id}`
      boardLink.innerHTML = boardName
      boardPara.appendChild(boardLink)
    } else {
      const boardValue = document.createElement('span')
      boardValue.classList.add('hardware-detector__detected_board__board__value')
      boardValue.innerHTML = boardName
      boardPara.appendChild(boardValue)
    }
    container.appendChild(boardPara)

    if (this.board.devices.length > 0) {
      const devicePara = document.createElement('p')
      devicePara.classList.add('hardware-detector__detected_board__device')

      const deviceLabel = document.createElement('span')
      deviceLabel.classList.add('hardware-detector__detected_board__device__label')
      deviceLabel.innerHTML = 'Devices:'
      devicePara.appendChild(deviceLabel)

      const deviceList = document.createElement('ul')
      deviceList.classList.add('hardware-detector__detected_board__device__value')
      this.board.devices.forEach(device => {
        const deviceItem = document.createElement('li')
        deviceItem.innerHTML = `<a href="/devices/${device.id}/">${device.name}</a>`
        deviceList.appendChild(deviceItem)
      })
      devicePara.appendChild(deviceList)

      container.appendChild(devicePara)
    }

    this.el.replaceChildren(container)
  }
}
