export default class SelectionControl {
  private readonly containerElement: HTMLElement

  constructor (containerElement: HTMLElement) {
    this.containerElement = containerElement
  }

  public clearSelections (): void {
    const selectedElements = this.containerElement.querySelectorAll('[checked]')
    if (selectedElements.length !== 0) {
      selectedElements.forEach((item: HTMLInputElement) => {
        item.checked = false
      })
      Array.from(selectedElements)[0].dispatchEvent(new Event('change'))
    }
  }
}
