import { DOMElementNotFound } from "../errors";

export default class GlobalNavigationSubmenu {
    containerElement: HTMLElement
    closeControlElement: HTMLElement

    constructor(containerElement: HTMLElement) {
        this.containerElement = containerElement;
        this.closeControlElement = this.getCloseControlElement();

        this.setupState()
        this.setupEventListeners()
    }

    private getCloseControlElement(): HTMLElement {
        const element = this.containerElement.querySelector<HTMLElement>('.keil-nav-mob__close-control');

        if (element == null) throw new DOMElementNotFound('Unable to find keil nav mobile close control element');

        return element;
    }

    private setupState(): void {
        if (this.containerElement.getAttribute('aria-expanded') == null) {
            this.containerElement.setAttribute('aria-expanded', 'false');
        }
    }

    private setupEventListeners(): void {
        this.containerElement.addEventListener('click', () => {
            this.openMenu();
        })
        this.closeControlElement.addEventListener('click', (event) => {
            event.stopPropagation();
            this.closeMenu();
        })
    }

    private openMenu(): void {
        this.containerElement.setAttribute('aria-expanded', 'true');
    }

    private closeMenu(): void {
        this.containerElement.setAttribute('aria-expanded', 'false');
    }
}