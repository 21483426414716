export default class ListFilter{
    searchElement: HTMLInputElement
    listItems: HTMLElement[]

    constructor (containerElement: HTMLElement){
        const searchElement = containerElement.querySelector("input")
        if(searchElement == null){
            throw new Error("Unable to find item filter input")
        }
        this.searchElement = searchElement

        this.setListItems(containerElement)

        this.setupEventListener()
    }

    private setListItems(containerElement: HTMLElement): void{
        const listItems = containerElement.querySelectorAll<HTMLElement>("[data-list-item]")
        this.listItems = Array.from(listItems)
    }

    private filterItems(query: string): void{
        const upperQuery = query.toUpperCase()
        for(const item of this.listItems){
            const label = item.querySelector("label")
            if(label?.innerText == null){
                return
            }
            
            const text = label?.innerText.toUpperCase()
            if(text.includes(upperQuery)){
                item.style.display = "block"
            }
            else{
                item.style.display = "none"
            }
        }
    }

    private preventFormSubmit(e): void{
        if(e.key === "enter"){
            e.preventDefault()
        }
    }

    private setupEventListener (): void{
        this.filterItems = this.filterItems.bind(this)
        this.searchElement.addEventListener("input", (e) => { this.filterItems((e.target as HTMLInputElement).value) })
        
        this.searchElement.addEventListener("keydown",(e) => {this.preventFormSubmit(e)})
    }
}