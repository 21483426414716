export class Device {
  constructor (
    public id: string,
    public name: string
  ) { }
}

export class Board {
  constructor (
    public id: string,
    public name: string,
    public vendorName: string,
    public detectCode: string,
    public cmsisEcosystemSupport: boolean,
    public devices: Device[]
  ) { }
}

export interface BoardRepositoryI {
  getBoards: () => Promise<Board[]>
}

export class GraphQlBoardRepository implements BoardRepositoryI {
  static readonly graphQlQuery = 'query { boards { id name detect_code cmsis { __typename } vendor { name } devices { id name } } }'

  constructor (
    private readonly graphQlHost: string = 'https://solar-search.api.keil.arm.com/'
  ) { }

  async getBoards (): Promise<Board[]> {
    const response = await fetch(this.graphQlHost, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query: GraphQlBoardRepository.graphQlQuery })
    })
    const json = await response.json()
    return json.data.boards.map((board) => {
      const devices = board.devices.map((device) => new Device(device.id, device.name))
      const cmsisEcosystemSupport = board.cmsis !== null
      return new Board(board.id, board.name, board.vendor.name, board.detect_code, cmsisEcosystemSupport, devices)
    })
  }
}
