/* eslint-disable no-undef */
import { createCookie, readCookie } from "./cookie_popup.js";

function getDismissButton(dismissable) {
  return dismissable.querySelector("[data-dismiss]");
}

function getDisabledBannersFromCookie() {
  const cookieValue = readCookie("disabled_banners");
  if (cookieValue) {
    return JSON.parse(cookieValue);
  }
  return [];
}

function setDisabledBannersCookie(disabledBannersArray) {
  const disabledBannersNew = JSON.stringify(disabledBannersArray);
  createCookie("disabled_banners", disabledBannersNew, 14);
}

up.compiler("[data-dismissable]", (dismissable) => {
  up.on(getDismissButton(dismissable), "click", (event, element) => {
    up.element.remove(dismissable);

    const disabledBannersArray = getDisabledBannersFromCookie();
    disabledBannersArray.push(dismissable.getAttribute("data-dismissable-id"));
    setDisabledBannersCookie(disabledBannersArray);
  });
});
