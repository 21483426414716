import { type Board } from "../services/BoardRepository";
import DetectedHardwareView from "./DetectedHardwareView";

export interface HardwareDetectorViewI {
  onRequestDetection: (cb: () => void) => void;
  setHardware: (board: Board) => void;
  setError: (err: Error) => void;
  setUnsupportedBrowser: () => void;
  render: () => void;
}

export default class HardwareDetectorView implements HardwareDetectorViewI {
  private detectedBoard?: Board;
  private errorMessage?: string;
  private loading: boolean = false;

  constructor(private readonly el: HTMLElement) {
    this.el = el;
    this.setLoading = this.setLoading.bind(this);
  }

  onRequestDetection(cb: () => void): void {
    this.el.querySelector("button")?.addEventListener("click", () => {
      this.setLoading();
      cb();
    });
  }

  private setLoading(): void {
    this.loading = true;
    this.render();
  }

  setHardware(board: Board): void {
    this.detectedBoard = board;
    this.render();
  }

  setUnsupportedBrowser(): void {
    this.errorMessage = `
      <p>Sorry, your browser does not appear to support WebUSB.</p>
      <p>Visit <a href="https://caniuse.com/webusb" target="_blank">https://caniuse.com/webusb</a> to learn more.</p>
    `;
    this.render();
  }

  setError(err: Error): void {
    console.error(err);
    this.errorMessage = `
      <p>Sorry, we were unable to identify your hardware.</p>
    `;
    this.render();
  }

  render(): void {
    if (this.errorMessage !== undefined) {
      this.el.innerHTML = `<div class="hardware-detector__error">
        ${this.errorMessage}
      </div>`;
    } else if (this.detectedBoard !== undefined) {
      new DetectedHardwareView(this.el, this.detectedBoard).render();
    } else if (this.loading) {
      this.el.innerHTML = `
        <div class="hardware-detector__loading">
          <i class="fas fa-spinner fa-spin"></i>
          Detecting hardware...
        </div>
      `;
    } else if ("usb" in navigator) {
      this.el.innerHTML = `
        <button id="hardware-detector"><i class="fal fa-plug"></i> Detect Connected Board</button>
      `;
    }
  }
}
