import { DOMElementNotFound } from "../errors"

export default class Accordion {
  containerElement: HTMLElement
  controlElement: HTMLElement
  contentElement: HTMLElement

  constructor (containerElement: HTMLElement) {
    this.containerElement = containerElement
    this.controlElement = this.getControlElement()
    this.contentElement = this.getContentElement()

    this.setupState()
    this.setupEventListeners()
  }

  private getControlElement (): HTMLElement {
    const controlElement = this.containerElement.querySelector<HTMLElement>('[data-accordion-control]')

    if (controlElement == null) throw new DOMElementNotFound('Unable to find accordion control element')

    return controlElement
  }

  private getContentElement (): HTMLElement {
    const contentElement = this.containerElement.querySelector<HTMLElement>('[data-accordion-content]')

    if (contentElement == null) throw new DOMElementNotFound('Unable to find accordion content element')

    return contentElement
  }

  private setupState (): void {
    if (this.containerElement.getAttribute('aria-expanded') == null) {
      this.containerElement.setAttribute('aria-expanded', 'false')
    }
  }

  private setupEventListeners (): void {
    this.controlElement.addEventListener('click', (event) => {
      this.toggleContent()
    })
  }

  private toggleContent (): void {
    this.containerElement.setAttribute(
      'aria-expanded',
      this.containerElement.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
    )
  }
}

export function initAccordion(): void {
  up.compiler('[data-accordion]', (accordionContainerElement) => {
    new Accordion(accordionContainerElement)
  })
}
