import WebUsbReader, { type WebUsbReaderI } from './WebUsbReader'

export class WebUsbUnsupportedError extends Error {
  constructor () {
    super('WebUSB is not supported by this browser')
  }
}

export interface ConnectedDeviceServiceI {
  getConnectedDeviceProductCode: () => Promise<string>
}

export default class WebUsbConnectedDeviceService implements ConnectedDeviceServiceI {
  constructor (
    private readonly webUsbReader: WebUsbReaderI = new WebUsbReader()
  ) {}

  async getConnectedDeviceProductCode (): Promise<string> {
    if (!this.webUsbReader.webUsbSupported()) {
      throw new WebUsbUnsupportedError()
    }
    const device = await this.webUsbReader.requestDevice()
    if (this.webUsbReader.isStLinkDevice(device)) {
      return await this.webUsbReader.getStDeviceProductCode(device)
    } else {
      return serialNumberToDetectCode(await this.webUsbReader.getDeviceSerial(device))
    }
  }
}

export function serialNumberToDetectCode (serialNumber: string): string {
  return ('0000' + serialNumber.substr(0, 4)).slice(-4)
}
