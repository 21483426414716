import DropdownListItem, { type ChildElement } from './DropdownListItem'

export interface DropdownListClassNames {
  list: string
  listItem: string
  title: string
}

export default class DropdownList {
  private readonly listItemClassname: string
  domElement: HTMLUListElement
  titleElement: HTMLLIElement
  private indexOfFocused: number = -1
  listItems: DropdownListItem[] = []

  navigateListItems: (event: KeyboardEvent) => void

  constructor (classNames: DropdownListClassNames) {
    this.listItemClassname = classNames.listItem
    this.domElement = DropdownList.buildDomElement(classNames.list)
    this.titleElement = DropdownList.buildTitleElement(classNames.title)

    this.navigateListItems = this.navigateListItemsOnKeyDown.bind(this)

    this.setupEventListeners()
  }

  private static buildDomElement (className: string): HTMLUListElement {
    const uListElement = document.createElement('ul')
    uListElement.classList.add(className)

    return uListElement
  }

  private static buildTitleElement (className: string): HTMLLIElement {
    const titleElement = document.createElement('li')
    titleElement.classList.add(className)

    return titleElement
  }

  private setupEventListeners (): void {
    this.domElement.addEventListener('mouseleave', this.unsetFocused.bind(this))
    this.domElement.addEventListener('dropdown-mouseenter', this.setFocussedOnMouseEnter.bind(this))
  }

  private hasListItems (): boolean {
    return !(this.listItems.length === 0)
  }

  private hasFocusedListItem (): boolean {
    return this.indexOfFocused > -1
  }

  private setFocused (indexOfNewFocus: number): void {
    if (!this.hasListItems()) return

    this.unsetFocused()
    this.listItems[indexOfNewFocus].toggleFocused()
    this.indexOfFocused = indexOfNewFocus
  }

  private unsetFocused (): void {
    if (!this.hasFocusedListItem()) return

    this.listItems[this.indexOfFocused].toggleFocused()
    this.indexOfFocused = -1
  }

  private setNextIndexAsFocused (): void {
    const newIndex = this.indexOfFocused + 1

    if (newIndex >= this.listItems.length) {
      this.unsetFocused()
    } else {
      this.setFocused(newIndex)
    }
  }

  private setPreviousIndexAsFocused (): void {
    let newIndex = this.indexOfFocused - 1

    if (newIndex === -1) {
      this.unsetFocused()
    } else {
      newIndex = newIndex > -1 ? newIndex : this.listItems.length - 1
      this.setFocused(newIndex)
    }
  }

  private selectFocused (): void {
    if (!this.hasFocusedListItem()) return

    this.listItems[this.indexOfFocused].select()
  }

  private navigateListItemsOnKeyDown (event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        this.setNextIndexAsFocused()
        break
      case 'ArrowUp':
        event.preventDefault()
        this.setPreviousIndexAsFocused()
        break
      case 'Enter':
        if (this.hasFocusedListItem()) {
          event.preventDefault()
          this.selectFocused()
        }
        break
    }
  }

  setTitle (title: string): void {
    const titleText = document.createTextNode(title)

    if (this.titleElement.firstChild != null) {
      this.titleElement.removeChild(this.titleElement.firstChild)
    }

    this.titleElement.appendChild(titleText)
  }

  setFocussedOnMouseEnter (event: Event): void {
    event.stopPropagation()
    this.setFocused(
      this.listItems.findIndex(listItem => (
        listItem.childElement.domElement === event.target
      ))
    )
  };

  setListItems (childElements: ChildElement[]): void {
    this.unsetFocused()
    for (const listItem of this.listItems) {
      listItem.remove()
    }

    if (childElements.length > 0) {
      this.domElement.appendChild(this.titleElement)
    } else if (this.domElement.contains(this.titleElement)) {
      this.domElement.removeChild(this.titleElement)
    }

    this.listItems = childElements.map((childElement) => (
      new DropdownListItem(this.listItemClassname, childElement)
    ))
    for (const listItem of this.listItems) {
      this.domElement.appendChild(listItem.domElement)
    }
  }
}
