import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Board = {
  __typename?: 'Board';
  cmsis_ecosystem_support: Scalars['Boolean'];
  debug_interfaces: Array<DebugInterface>;
  description?: Maybe<Scalars['String']>;
  detect_code?: Maybe<Scalars['String']>;
  devices: Array<Device>;
  documentation: Array<Documentation>;
  features: Array<BoardFeature>;
  guide_url?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pack_url?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  vendor: Vendor;
};

export type BoardFeature = {
  __typename?: 'BoardFeature';
  category: Scalars['String'];
  default_name: Scalars['String'];
  detail: Scalars['String'];
};

export type BoardSearchResult = {
  __typename?: 'BoardSearchResult';
  cmsis_ecosystem_support: Scalars['Boolean'];
  example_projects_count: Scalars['Int'];
  highlights: SearchResultHighlights;
  id: Scalars['String'];
  image_url?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  revision?: Maybe<Scalars['String']>;
  vendor: Vendor;
};

export type BoardSearchResultCollection = {
  __typename?: 'BoardSearchResultCollection';
  facets: SearchResultCollectionFacets;
  metadata: CollectionMetaData;
  results: Array<BoardSearchResult>;
};

export type BoardSuggestion = {
  __typename?: 'BoardSuggestion';
  id: Scalars['String'];
  name: Scalars['String'];
  revision?: Maybe<Scalars['String']>;
};

export type CollectionMetaData = {
  __typename?: 'CollectionMetaData';
  total: Scalars['Int'];
};

export type DebugInterface = {
  __typename?: 'DebugInterface';
  adapter: Scalars['String'];
  connector: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  description?: Maybe<Scalars['String']>;
  family: Family;
  id: Scalars['String'];
  memory: Array<Memory>;
  name: Scalars['String'];
  pack_url?: Maybe<Scalars['String']>;
  processors: Array<Processor>;
  source_pack_id: SourcePackId;
  sub_family?: Maybe<Family>;
  vendor: Vendor;
};

export type DeviceSearchResult = {
  __typename?: 'DeviceSearchResult';
  cores: Array<Scalars['String']>;
  family: Scalars['String'];
  highlights: SearchResultHighlights;
  id: Scalars['String'];
  name: Scalars['String'];
  sub_family?: Maybe<Scalars['String']>;
  vendor: Vendor;
};

export type DeviceSearchResultCollection = {
  __typename?: 'DeviceSearchResultCollection';
  facets: SearchResultCollectionFacets;
  metadata: CollectionMetaData;
  results: Array<DeviceSearchResult>;
};

export type Documentation = {
  __typename?: 'Documentation';
  category: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Facet = {
  __typename?: 'Facet';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Family = {
  __typename?: 'Family';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Layer = {
  __typename?: 'Layer';
  name: Scalars['String'];
  title: Scalars['String'];
};

export type Memory = {
  __typename?: 'Memory';
  name: Scalars['String'];
  size: Scalars['Int'];
};

export type Processor = {
  __typename?: 'Processor';
  core: Scalars['String'];
  cortexm_vector_extensions: Scalars['String'];
  digital_signal_processor: Scalars['String'];
  endian: Scalars['String'];
  floating_point_unit: Scalars['String'];
  max_core_clock_frequency: Scalars['Int'];
  memory_protection_unit: Scalars['String'];
  trust_zone: Scalars['String'];
};

export type Project = {
  __typename?: 'Project';
  description: Scalars['String'];
  download_url: Scalars['String'];
  hardware_id: Scalars['String'];
  id: Scalars['String'];
  layers: Array<Layer>;
  name: Scalars['String'];
};

export type ProjectCollection = {
  __typename?: 'ProjectCollection';
  metadata: CollectionMetaData;
  results: Array<Project>;
};

export type Query = {
  __typename?: 'Query';
  boards: Array<Board>;
  get_board?: Maybe<Board>;
  get_board_suggestions: Array<BoardSuggestion>;
  get_device?: Maybe<Device>;
  get_family?: Maybe<Family>;
  search_boards: BoardSearchResultCollection;
  search_devices?: Maybe<DeviceSearchResultCollection>;
  search_projects: ProjectCollection;
};


export type QueryGet_BoardArgs = {
  id: Scalars['String'];
};


export type QueryGet_Board_SuggestionsArgs = {
  cmsis_ecosystem_support?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
};


export type QueryGet_DeviceArgs = {
  id: Scalars['String'];
};


export type QueryGet_FamilyArgs = {
  id: Scalars['String'];
};


export type QuerySearch_BoardsArgs = {
  cmsis_ecosystem_support?: InputMaybe<Scalars['Boolean']>;
  core?: InputMaybe<Array<Scalars['String']>>;
  device_id?: InputMaybe<Scalars['String']>;
  has_example_projects?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  vendor_slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySearch_DevicesArgs = {
  core?: InputMaybe<Array<Scalars['String']>>;
  family?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<Scalars['String']>;
  sub_family?: InputMaybe<Scalars['String']>;
  vendor_slug?: InputMaybe<Array<Scalars['String']>>;
};


export type QuerySearch_ProjectsArgs = {
  hardware_id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  q?: InputMaybe<Scalars['String']>;
};

export type SearchResultCollectionFacets = {
  __typename?: 'SearchResultCollectionFacets';
  cores: Array<Facet>;
  vendors: Array<Facet>;
};

export type SearchResultHighlights = {
  __typename?: 'SearchResultHighlights';
  name?: Maybe<Scalars['String']>;
};

export type SourcePackId = {
  __typename?: 'SourcePackId';
  name: Scalars['String'];
  vendor: Scalars['String'];
  version: Scalars['String'];
};

export type Vendor = {
  __typename?: 'Vendor';
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type GetBoardSuggestionsQueryVariables = Exact<{
  cmsisEcosystemSupport?: InputMaybe<Scalars['Boolean']>;
  query?: InputMaybe<Scalars['String']>;
}>;


export type GetBoardSuggestionsQuery = { __typename?: 'Query', get_board_suggestions: Array<{ __typename?: 'BoardSuggestion', id: string, name: string, revision?: string | null }> };


export const GetBoardSuggestionsDocument = gql`
    query getBoardSuggestions($cmsisEcosystemSupport: Boolean, $query: String) {
  get_board_suggestions(
    cmsis_ecosystem_support: $cmsisEcosystemSupport
    q: $query
  ) {
    id
    name
    revision
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getBoardSuggestions(variables?: GetBoardSuggestionsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GetBoardSuggestionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBoardSuggestionsQuery>(GetBoardSuggestionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBoardSuggestions', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;