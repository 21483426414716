/* eslint-disable @typescript-eslint/ban-types */
import type RecentSearchService from '../services/recent_searches/service'
import type SearchService from '../services/graphql_service/service'
import DropdownList, { type DropdownListClassNames } from './DropdownList'
import RecentSearch from './RecentSearch'
import SuggestionElement from './SuggestionElement'

function debounce (func: Function, timeout: number = 100): (...args: any[]) => void {
  let timer: NodeJS.Timeout
  return (...args: any[]) => {
    clearTimeout(timer)
    timer = setTimeout(() => { func.apply(this, args) }, timeout)
  }
}

export interface SearchBoxServices {
  search: SearchService
  recentSearch: RecentSearchService
}

export default class SearchBox {
  containerElement: HTMLElement
  inputElement: HTMLInputElement
  private readonly services: SearchBoxServices
  dropdownList: DropdownList

  private readonly debouncedSetDropdownListItems: (...args: any) => void

  constructor (containerElement: HTMLElement, debounceTimeout: number, services: SearchBoxServices, dropdownListClassNames: DropdownListClassNames) {
    this.containerElement = containerElement
    const inputElement = containerElement.querySelector<HTMLInputElement>(`.${containerElement.className}__input`)
    if (inputElement == null) {
      throw new Error('Unable to find search box input element.')
    }
    this.inputElement = inputElement
    this.services = services
    this.dropdownList = new DropdownList(dropdownListClassNames)

    this.debouncedSetDropdownListItems = debounce(async () => { await this.setDropdownListItems(this.inputElement.value) }, debounceTimeout)

    this.render()
    this.setupEventListeners()
  }

  private render (): void {
    this.containerElement.appendChild(this.dropdownList.domElement)
  }

  private setupEventListeners (): void {
    this.inputElement.addEventListener('input', () => {
      this.debouncedSetDropdownListItems()
    })
    this.containerElement.addEventListener('keydown', this.dropdownList.navigateListItems)
    this.containerElement.addEventListener('recentsearch-remove', () => {
      this.setDropdownListItems('').catch(console.error)
    })
  }

  private addWithItemsModifier (): void {
    this.inputElement.classList.add(`${this.inputElement.classList[0]}--with-items`)
  }

  private removeWithItemsModifier (): void {
    this.inputElement.classList.remove(`${this.inputElement.classList[0]}--with-items`)
  }

  private async getSuggestions (query: string): Promise<SuggestionElement[]> {
    const suggestions = query !== '' ? await this.services.search.getSuggestions(query) : []

    return suggestions.map((suggestion) => (
      new SuggestionElement(
        this.buildNameWithRevision(suggestion.name, suggestion.revision),
        suggestion.url
      )
    ))
  }

  private buildNameWithRevision (name: string, revision: string | null): string {
    if (revision != null) {
      return name + ' ' + revision
    }
    return name
  }

  private getRecentSearches (): RecentSearch[] {
    return this.services.recentSearch.getRecentSearches().map((recentSearch) => (
      new RecentSearch(recentSearch.query, recentSearch.url)
    ))
  }

  async setDropdownListItems (query: string): Promise<void> {
    const title = query !== '' ? 'Suggested Boards' : 'Recent Searches'
    const listItems = query !== '' ? await this.getSuggestions(query) : this.getRecentSearches()

    this.dropdownList.setTitle(title)
    this.dropdownList.setListItems(listItems)

    if (listItems.length > 0) {
      this.addWithItemsModifier()
    } else {
      this.removeWithItemsModifier()
    }
  }
}
