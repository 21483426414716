import * as client from './client'
import { type BoardSuggestion } from './graphql/types'

export interface Suggestion {
  name: string
  revision: string | null
  url: string
}

export default class SearchService {
  private readonly host: string
  private readonly boardEndpoint: string

  constructor (host: string, boardEndpoint: string) {
    this.host = host
    this.boardEndpoint = boardEndpoint
  }

  buildSuggestion (suggestionResponse: BoardSuggestion): Suggestion {
    return { name: suggestionResponse.name, revision: suggestionResponse.revision ?? null, url: `${this.boardEndpoint}${suggestionResponse.id}` }
  }

  async getSuggestions (query: string): Promise<Suggestion[]> {
    return await client.getSuggestions(this.host, query, true).then((suggestionResponse) => {
      return suggestionResponse.map(
        (suggestionResponse) => (this.buildSuggestion(suggestionResponse))
      )
    })
  }
}
