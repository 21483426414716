import HardwareDetector, {
  type HardwareDetectorI
} from '../services/HardwareDetector'
import { WebUsbUnsupportedError } from '../services/ConnectedDeviceService'
import HardwareDetectorView, {
  type HardwareDetectorViewI
} from '../views/HardwareDetectorView'

export default class HardwareDetectionWidget {
  hardwareDetector: HardwareDetectorI
  hardwareDetectorView: HardwareDetectorViewI

  constructor (
    hardwareDetector: HardwareDetectorI,
    hardwareDetectorView: HardwareDetectorViewI
  ) {
    this.hardwareDetector = hardwareDetector
    this.hardwareDetectorView = hardwareDetectorView

    this.hardwareDetectorView.render()
    this.bindEvents()
  }

  private bindEvents (): void {
    this.hardwareDetectorView.onRequestDetection(
      this.detectHardware.bind(this)
    )
  }

  detectHardware (): void {
    this.hardwareDetector
      .detectHardware()
      .then((board) => {
        this.hardwareDetectorView.setHardware(board)
      })
      .catch((err) => {
        if (err instanceof WebUsbUnsupportedError) {
          this.hardwareDetectorView.setUnsupportedBrowser()
        } else {
          this.hardwareDetectorView.setError(err)
        }
      })
  }
}

export function initHardwareDetector (): void {
  up.compiler('[data-web-usb-detect-widget]', (controlElement: HTMLElement) => {
    new HardwareDetectionWidget(
      new HardwareDetector(),
      new HardwareDetectorView(controlElement)
    )
  });
}
