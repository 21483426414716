const keyCodes = {
  ENTER: 13,
  UP_ARROW: 38,
  DOWN_ARROW: 40,
};

// eslint-disable-next-line no-unused-vars
class AutocompleteInput {
  constructor(container) {
    this.container = container;
    this.input = container.getElementsByClassName(
      container.className + "__input",
    )[0];
    this.suggestions = container.getElementsByClassName(
      container.className + "__suggestions",
    )[0];
    this.focusedSuggestionIndex = -1;
  }

  setFocusedSuggestion(newIndex) {
    this.removeFocusedSuggestion();

    this.focusedSuggestionIndex = newIndex;
    const focusedSuggestion =
      this.suggestions.children[this.focusedSuggestionIndex];
    focusedSuggestion.classList.add(
      this.container.className + "__suggestion--focused",
    );
  }

  removeFocusedSuggestion() {
    for (const suggestion of this.suggestions.children) {
      suggestion.classList.remove(
        this.container.className + "__suggestion--focused",
      );
    }
  }

  resetFocusedSuggestionIndex() {
    this.focusedSuggestionIndex = -1;
  }

  setInputBoxValue(value) {
    this.input.value = value;
    this.input.dispatchEvent(new Event("change"));
    this.input.blur();
  }

  setNextIndexAsFocused() {
    let newIndex = this.focusedSuggestionIndex + 1;
    if (newIndex >= this.suggestions.children.length) newIndex = 0;
    this.setFocusedSuggestion(newIndex);
  }

  setPreviousIndexAsFocused() {
    let newIndex = this.focusedSuggestionIndex - 1;
    if (newIndex < 0) newIndex = this.suggestions.children.length - 1;
    this.setFocusedSuggestion(newIndex);
  }

  navigateSuggestionsOnKeyPress = (event) => {
    if (event.keyCode === keyCodes.DOWN_ARROW) {
      this.setNextIndexAsFocused();
    } else if (event.keyCode === keyCodes.UP_ARROW) {
      this.setPreviousIndexAsFocused();
    } else if (event.keyCode === keyCodes.ENTER) {
      event.preventDefault();
      this.suggestions.children[this.focusedSuggestionIndex].click();
    }
  };

  attachKeyboardEventListener() {
    this.container.addEventListener(
      "keydown",
      this.navigateSuggestionsOnKeyPress,
    );
  }

  setFocussedOnMouseEnter = (event) => {
    this.setFocusedSuggestion(
      Array.prototype.indexOf.call(this.suggestions.children, event.target),
    );
  };

  setInputValueOnClick = (event) => {
    this.setInputBoxValue(event.target.innerHTML);
  };

  removeFocussedSuggestionOnLeave = (event) => {
    this.removeFocusedSuggestion();
    this.resetFocusedSuggestionIndex();
  };

  attachSuggestionsEventListeners() {
    this.suggestions.addEventListener(
      "mouseleave",
      this.removeFocussedSuggestionOnLeave,
    );

    for (const suggestion of this.suggestions.children) {
      suggestion.addEventListener("mouseenter", this.setFocussedOnMouseEnter);
      suggestion.addEventListener("click", this.setInputValueOnClick);
    }
  }

  init() {
    this.attachKeyboardEventListener();
    this.attachSuggestionsEventListeners();
  }

  removeKeyboardEventListener() {
    this.container.removeEventListener(
      "keydown",
      this.navigateSuggestionsOnKeyPress,
    );
  }

  removeSuggestionsEventListeners() {
    this.suggestions.removeEventListener(
      "mouseleave",
      this.removeFocussedSuggestionOnLeave,
    );

    for (const suggestion of this.suggestions.children) {
      suggestion.removeEventListener(
        "mouseenter",
        this.setFocussedOnMouseEnter,
      );
      suggestion.removeEventListener("click", this.setInputValueOnClick);
    }
  }

  cleanup() {
    this.removeKeyboardEventListener();
    this.removeSuggestionsEventListeners();
  }
}
