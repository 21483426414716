export const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: Pick<HTMLElement, 'clientWidth' | 'clientHeight' | 'scrollWidth' | 'scrollHeight'>): boolean => {
  return scrollHeight > clientHeight || scrollWidth > clientWidth;
}

export default class ExtendableContent {
  element: HTMLElement
  extensionButton: HTMLElement | undefined

  constructor(element: HTMLElement) {
    this.element = element;

    this.attachExtensionButton();
    this.setupEventListeners();
  }

  private attachExtensionButton(): void {
    if (isOverflown(this.element)) {
      const extensionButton = document.createElement('button');
      extensionButton.setAttribute('data-extendable-content-control', "");
      extensionButton.textContent = "View more";
      this.extensionButton = extensionButton;

      this.element.insertAdjacentElement("afterend", extensionButton);
    }
  }

  private setupEventListeners(): void {
    if (this.extensionButton !== undefined) {
      this.extensionButton.addEventListener('click', (event) => {
        this.toggleContent()
      })
    }
  }

  private toggleContent(): void {
    const isExtended = this.element.getAttribute('data-extended') === 'true';
    this.element.setAttribute('data-extended', isExtended ? 'false' : 'true');
    if (this.extensionButton !== undefined) {
      this.extensionButton.textContent = isExtended ? "View more" : "View less";
    }
  }
}

export function initExtendableContent(): void {
  up.compiler('[data-extendable-content]', (extendableContentElement) => {
    new ExtendableContent(extendableContentElement)
  })
}
