import Dropdown from './Dropdown'
import SelectionControl from './SelectionControl'

export default class MultiSelectDropdown {
  dropdown: Dropdown

  constructor (controlElement: HTMLButtonElement) {
    this.dropdown = new Dropdown(controlElement)
  }

  public clearSelections (): void {
    new SelectionControl(this.dropdown.contentElement).clearSelections()
  }

  public setupClearButtonListener (clearButton: HTMLButtonElement): void {
    clearButton.addEventListener('click', (event) => {
      event.stopPropagation()
      this.clearSelections()
    })
  }

  public disableIfEmpty (): void {
    this.dropdown.controlElement.disabled = this.dropdown.contentElement.querySelector('input') == null
  }
}
