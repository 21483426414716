/* eslint-disable no-undef */
function updateUrl(newUrl) {
  window.history.replaceState(window.history.state, "", newUrl);
}

function getHardwareLinks(tabs) {
  return tabs.getElementsByTagName("a");
}

function removeQueryParamString(url) {
  return url.split("?")[0];
}

up.compiler(
  ".board-tabs, .device-tabs, .vendor-tabs, .pack-tabs",
  (hardwareTabs) => {
    up.on(
      getHardwareLinks(hardwareTabs),
      "up:link:follow",
      (event, element) => {
        updateUrl(element.href);
      },
    );
  },
);

up.compiler(".board-projects__form", (projectsSearch) => {
  up.on(projectsSearch, "up:form:submit", (event, form) => {
    const url = new URL(location.href);
    url.search = event.params.toQuery();
    updateUrl(url);
  });
});

up.compiler(
  ".up-board-listing-breadcrumb, .up-device-listing-breadcrumb, .up-pack-listing-breadcrumb",
  (hardwareBreadcrumb) => {
    up.on(hardwareBreadcrumb, "up:link:follow", (event, element) => {
      // Ignore unpoly's up-back if the previous url is not the same as the href on the link.
      // Otherwise allow it to preserve query parameters, such as search state.
      const breadcrumbUrl = element.getAttribute("href");
      const previousUrl = event.renderOptions.url;
      if (removeQueryParamString(previousUrl) !== breadcrumbUrl) {
        event.renderOptions.url = breadcrumbUrl;
      }
    });
  },
);
